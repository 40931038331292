<template>
  <div class="el-column">
    <div class="el-column__navbar">
      <logo-wrapper :account="account" />
    </div>
    <div class="ton-label" v-if="account.organization === 'ton'">
      <label class="emd-text _color_default">{{ $t('tonLabel') }}</label>
      <label class="emd-text _color_primary">{{ account.accountName }}</label>
    </div>
    <div class="el-section">
      <router-view/>
    </div>
    <div class="el-section">
      <emd-footer-external />
    </div>
  </div>
</template>

<script>
import LogoWrapper from '@/components/project/molecules/LogoWrapper.vue'
import EmdFooterExternal from '@/components/emerald/organisms/EmdFooterExternal.vue'

export default {
  name: 'Motherboard',
  components: {
    LogoWrapper,
    EmdFooterExternal
  },
  computed: {
    account () {
      if (this.$store.getters.tokenData) {
        const paymentLink = this.$store.getters.tokenData
        return {
          name: paymentLink.account_info?.account_name,
          logo: paymentLink.layout_settings?.image_url,
          website: paymentLink.layout_settings?.url,
          accountName: paymentLink.account_settings?.account_name,
          organization: paymentLink.account_settings?.organization
        }
      }
      return null
    }
  }
}
</script>

<style>
.ton-label {
  width: max-content;
  padding-top: 15px;
  font-weight: 800;
  size: 14px;
}
</style>

<i18n>
{
  "pt-BR": {
    "tonLabel": "Vendido por: "
  },
  "en-US": {
    "tonLabel": "Sold by: "
  }
}
</i18n>
