<template>
  <div v-if="failedStatus">
    <template>
      <div class="emd-container__content eu-align_center">
        <div class="el-chunk">
          <h2 class="emd-text _size_page">{{ $t('failed.title') }}</h2>
        </div>
        <div class="el-chunk" style="white-space: pre-line;">
          <p>{{ $t('failed.body') }}</p>
        </div>
      </div>
    </template>
  </div>
  <div v-else>
    <template v-if="paymentWithBoleto">
      <div class="emd-container__content eu-align_center" v-if="this.isSubscription">
        <div class="el-chunk">
          <h2 class="emd-text _size_page">{{ $t('boleto.subscription.title') }}</h2>
        </div>
        <div class="el-chunk" style="white-space: pre-line;">
          <p>{{ $t('boleto.subscription.body') }}</p>
        </div>
      </div>
       <div class="emd-container__content eu-align_center" v-else>
        <div class="el-chunk">
          <h2 class="emd-text _size_page">{{ $t('boleto.title') }}</h2>
        </div>
        <div class="el-chunk" style="white-space: pre-line;">
          <p>{{ $t('boleto.body') }}</p>
        </div>
        <div class="el-chunk">
          <img v-bind:src="status.boleto.barcode" v-bind:alt="status.boleto.barcode" width="435" />
          <p ref="boleto_code">{{ status.boleto.line }}</p>
        </div>
      </div>
      <footer class="emd-container__footer" v-if="!this.isSubscription">
        <button class="emd-btn _color_secondary _block_mobile _merchant_primary" @click="copy(status.boleto.line)">
          <i class="uil uil-copy" />
          {{ $t('boleto.btn.copy') }}
        </button>
        <a class="emd-btn _color_primary _block_mobile _merchant_secondary" v-bind:href="status.boleto.pdf" target="_blank" rel="noopener noreferrer" >
          {{ $t('boleto.btn.print') }}
        </a>
      </footer>
    </template>
    <template v-if="paymentWithCreditCard">
      <div class="emd-container__content eu-align_center">
        <div class="el-chunk">
          <img v-if="theme === 'mundipagg'" src="@/assets/thumbs-up.png" />
          <success-icon v-if="theme === 'pagarme'" />
        </div>
        <div class="el-chunk">
          <h2 class="emd-text _size_page">{{ $t('credit_card.title') }}</h2>
        </div>
        <div class="el-chunk" style="white-space: pre-line;">
          <p>{{ $t('credit_card.body') }}</p>
        </div>
      </div>
    </template>
    <template v-if="paymentWithPix">
      <div class="emd-container__content eu-align_center">
        <div class="el-chunk">
          <h2 class="emd-text _size_page">{{ $t('pix.title') }}</h2>
        </div>
        <div class="el-chunk">
          <img v-bind:src="status.pix.qr_code_url" v-bind:alt="status.pix.qr_code" width="250" />
          <p class="qr_code" ref="qr_code">{{ status.pix.qr_code }}</p>
          <button class="emd-btn _color_secondary _block_mobile _merchant_primary" @click="copy(status.pix.qr_code)">
            <i class="uil uil-copy" />
            {{ $t('pix.btn.copy') }}
          </button>
        </div>
        <div class="el-chunk eu-justify_center eu-display_flex" style="white-space: pre-line;">
          <div class="eu-display_flex">
            <ol class="steps emd-list _space_base eu-align_left">
              <li v-for="(step, index) in $t('pix.steps')" v-html="step"  v-bind:key="index">
                {{step}}
              </li>
            </ol>
          </div>
        </div>
        <div class="el-chunk eu-justify_center eu-align-items_center eu-display_flex eu-column" style="white-space: pre-line;">
          <div v-for="(info, index) in $t('pix.infos')" v-bind:key="index" class="eu-basis_7 eu-basis_12_mobile eu-display_flex eu-ma_2">
            <div class="eu-align_left" v-if="info.date">
              {{info.message}} <b>{{status.pix.expires_at | date('dateAndHour')}}</b>
            </div>
            <div class="eu-align_left" v-if="!info.date && !info.redirect">
              {{info.message}}
            </div>
          </div>
        </div>
      </div>
      <footer class="emd-container__footer">
      </footer>
    </template>
  </div>
</template>

<script>
import SuccessIcon from '@/components/project/globals/GlobalSuccess.vue'
import { onVisitPage, onPixCopyCode } from '@/utils/analitica/events'

export default {
  name: 'Success',
  components: {
    SuccessIcon
  },
  data () {
    return {
      counter: 10
    }
  },
  created () {
    if (this.paymentWithCreditCard) {
      onVisitPage.events.view.onVisitPage('succcess')
    } else if (this.paymentWithPix) {
      onVisitPage.events.view.onVisitPage('pix_succcess')
    }
  },
  computed: {
    status () {
      return this.$store.getters.status
    },
    paymentMethod () {
      return this.$store.getters.payment.selectedPaymentMethod
    },
    responseData () {
      return this.$store.getters.responseData
    },
    isLightbox () {
      return this.$store.getters.isLightbox
    },
    redirectUrl () {
      return `${this.responseData.success_url}?order_id=${this.responseData.id}`
    },
    bankTransferInfo () {
      return this.responseData.payments.find(item => Object.prototype.hasOwnProperty.call(item, 'bank_transfer')).bank_transfer
    },
    pendingDebit () {
      return (this.responseData.status === 'processing' && this.responseData.payments[0].status === 'pending_authentication')
    },
    theme () {
      return this.$store.getters.whitelabel.theme
    },
    paymentWithBoleto () {
      return this.paymentMethod === 'boleto' || this.paymentMethod === 'credit_card_and_boleto'
    },
    paymentWithCreditCard () {
      return this.paymentMethod === 'credit_card' || this.paymentMethod === 'apple_pay' || this.paymentMethod === 'google_pay'
    },
    paymentWithDebitCard () {
      return this.paymentMethod === 'debit_card'
    },
    paymentWithBankTransfer () {
      return this.paymentMethod === 'bank_transfer'
    },
    paymentWithPix () {
      return this.paymentMethod === 'pix'
    },
    failedStatus () {
      return this.status.order_status === 'failed'
    },
    isSubscription () {
      return this.$store.getters.tokenData.payment_link_type === 'subscription'
    }
  },
  methods: {
    copy (item) {
      onPixCopyCode.events.view.onPixCopyCode()
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(item).then(() => {}, (err) => {
          throw new Error(err)
        })
      } else {
        const textArea = document.createElement('textarea')
        textArea.value = item
        textArea.style.top = '0'
        textArea.style.left = '0'
        textArea.style.position = 'fixed'

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
          document.execCommand('copy')
        } catch (err) {
          throw new Error(err)
        }

        document.body.removeChild(textArea)
      }
    }
  }
}
</script>

<style scoped>
    p.qr_code {
      height: 1px;
      overflow: hidden;
    }
    ol.steps {
      padding-left: 25px;
    }
</style>

<i18n>
  {
    "pt-BR": {
      "failed": {
        "title": "Falha ao realizar pedido",
        "body": "Houve uma falha ao processar seu pagamento. Tente novamente"
      },
      "credit_card": {
        "title": "Pedido realizado com sucesso!",
        "body": "Seu pedido está sendo processado. Você receberá uma confirmação."
      },
      "debit_card": {
        "title": "Pedido realizado com sucesso!",
        "body": {
          "processing": "Seu pedido está sendo processado. Você receberá uma confirmação.",
          "pending": "Para sua compra ser confirmada é necessário que você autorize a transação."
        },
        "authenticate": "Autenticar transação"
      },
      "boleto": {
        "title": "Pedido criado com sucesso!",
        "body": "Estamos aguardando a confirmação do pagamento do boleto abaixo.\nImprima o boleto e pague no banco ou pela internet utilizando o código de barras.",
        "btn": {
          "copy": "Copiar código",
          "print": "Imprimir boleto"
        },
        "subscription" : {
          "title": "Assinatura criada com sucesso!",
          "body": "Você receberá o boleto e poderá realizar o pagamento pelo banco ou pela internet utilizando o código de barras."
        }
      },
      "pix": {
        "title": "Pedido criado com sucesso!",
        "steps": [
          "<b>Aponte seu celular para essa tela</b> para capturar o código",
          "Abra seu <b>aplicativo  de pagamentos</b>",
          "Confirme os dados e <b>finalize o pagamento pelo aplicativo</b>",
          "Enviaremos uma <b>confirmação de compra</b> pra você"
        ],
        "infos": [
          {"message": "Você também pode finalizar o pagamento copiando e colando o código em seu aplicativo"},
          {"message": "Essa chave PIX é válida até: ", "date": true},
          {"message": "Para retornar a loja ", "redirect": true}
        ],
        "btn": {
          "copy": "Copiar código",
          "print": "Imprimir boleto",
          "click": "clique aqui."
        }
      }
    },
    "en-US": {
      "failed": {
        "title": "Failed to place your order",
        "body": "There was a failure processing your order. Try again"
      },
      "credit_card": {
        "title": "Your order has been successfully placed",
        "body": "Your order is being processed. You will receive a confirmation."
      },
      "debit_card": {
        "title": "Your order has been successfully placed",
        "body": {
          "processing": "Your order is being processed. You will receive a confirmation.",
          "pending": "Authorize your transaction to confirm your order"
        },
        "authenticate": "Authenticate transaction"
      },
      "boleto": {
        "title": "Your order was successfully created",
        "body": "We're waiting the boleto's confirmation.\nPrint the boleto and pay in the bank or through the internet using the barcode.",
        "btn": {
          "copy": "Copy code",
          "print": "Print boleto"
        },
         "subscription" : {
          "title": "Your subscription was successfully created!",
          "body": "You will receive the boleto and will be able to pay in the bank or through the internet using the barcode."
        }
      },
      "bank_transfer": {
        "title": "Thank you!",
        "body": "Make the bank transfer for your purchase be confirmed.",
        "goToBank": "Go to the bank"
      },
      "pix": {
        "title": "Your order was successfully created",
        "steps": [
          "<b>Point your phone at this screen</b> to capture the code",
          "Open <b>your payments app</b>",
          "Confirm the data and <b>finalize the payment by the application</b>",
          "We will send you a <b>purchase confirmation</b>"
        ],
        "infos": [
          {"message":"You can also finalize the payment by copying and pasting the code into your application."},
          {"message": "This PIX key is valid until: ", "date":true},
          {"message":"To return to the store ", "redirect": true}
        ],
        "btn": {
          "copy": "Copy code",
          "print": "Print boleto",
          "click": "click here."
        }
      }
    }
  }
  </i18n>
